import { FILTER_TYPES } from '@/constants/filterTypes';
import type { ColumnFilter, PaginatedResponse } from '@witmetrics/api-client';

type ResponsePagination = PaginatedResponse<any>['pagination'];

export interface Pagination
  extends Omit<ResponsePagination, 'pageCount' | 'rowCount'> {
  pageCount?: number;
  rowCount?: number;
}

export function buildColumnFilter<T>(
  column: string,
  comparisonOperator: ColumnFilter['comparisonOperator'],
  value: T
) {
  return {
    column,
    comparisonOperator,
    value,
    filterType: FILTER_TYPES.COLUMN_VALUE,
  };
}
