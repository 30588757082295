import { forwardRef, MouseEvent } from 'react';
import { twMerge } from 'tailwind-merge';
import {
  default as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
} from '@mui/material/IconButton';
import Tooltip from '@/components/Tooltip';
import { styles } from './utils';

export interface IconButtonProps extends MuiIconButtonProps {
  disabled?: boolean;
  title?: string;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    { disabled = false, className = '', style = {}, title, onClick, ...props },
    ref
  ) => {
    const button = (
      <MuiIconButton
        ref={ref}
        disabled={disabled}
        className={twMerge(
          disabled ? 'not-allowed' : 'cursor-pointer',
          className
        )}
        sx={{ ...styles.iconButton, ...style }}
        onClick={(e) => (disabled ? null : onClick(e))}
        {...props}
      />
    );
    return title ? <Tooltip title={title}>{button}</Tooltip> : button;
  }
);

export default IconButton;
