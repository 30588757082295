import { type ColorRepresentation, DirectionalLight } from 'three';

export function getDirectionalLights() {
  return [
    buildLight(0, 200, 0),
    buildLight(200, 200, -100),
    buildLight(-100, -200, -50),
    buildLight(50, 20, 50),
  ];
}

function buildLight(
  x: number,
  y: number,
  z: number,
  options: { color?: ColorRepresentation; intensity?: number } = {}
) {
  const light = new DirectionalLight(
    options.color || 0xffffff,
    options.hasOwnProperty('intensity') ? options.intensity : 1
  );
  light.position.set(x, y, z);
  return light;
}
